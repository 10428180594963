/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import Content from "./Content";
import { Navigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/path";
import { AuthenticateContext } from "../../context/Auth";
import { scrollToTop } from "../../utils";
import LeftSidebar from "./sidebar/Sidebar";
import { getRequest } from "../../services/fetch";
import { toast } from "react-toastify";
import AppLoading from "../common/AppLoding";

interface layoutProps {
  title: string | any;
  children: any;
  moduleCode: any;
}

const Layout: React.FC<layoutProps> = ({ children, title, moduleCode }) => {
  const storeOpen = localStorage.getItem("open");
  const newOpen = storeOpen ? (storeOpen === "true" ? true : false) : true;
  const [open, setOpen] = React.useState(newOpen);
  const [loading, setLoading] = React.useState(true);
  const [, setModuleData] = React.useState<any>([]);
  const { userData } = React.useContext(AuthenticateContext);
  const [, , handleSetRoleModule] = useContext(AuthenticateContext);

  const openSidebar = () => {
    const isExpanded: any = open ? false : true;
    localStorage.setItem("open", isExpanded);
    setOpen(isExpanded);
  };
  const handleGetRoleModule = async () => {
    await getRequest({
      route: `role-module/code/${moduleCode}`,
    }).then((res) => {
      if (res.statusCode === 200) {
        handleSetRoleModule(res.data);
        setModuleData(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      } else {
        toast.error(res.message);
      }
    });
  };

  React.useEffect(() => {
    handleGetRoleModule();
    setTimeout(() => {
      scrollToTop();
    }, 0);
  }, []);

  if (userData?.isAutharize) {
    return <Navigate to={PATH_AUTH.root} />;
  }

  return (
    <>
      <div>
        <LeftSidebar open={open} />
        <Content title={title} open={open} onOpenSidebar={openSidebar}>
          {loading ? <AppLoading /> : children}
        </Content>
      </div>
    </>
  );
};

export default Layout;
