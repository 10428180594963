import React from "react";
import Layout from "../components/layout/Layout";
import menuItems from "../components/layout/sidebar/Menus";

interface Props {
  layout?: boolean;
}

const AccessDenied: React.FC<Props> = ({ layout = false }) => {
  const Info = () => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <>
            <div className="d-flex justify-content-center align-items-center w-50 mb-4">
              <img
                src="/images/access-denied.svg"
                alt="Access denied"
                className="img-fluid"
              />
            </div>
            <h1 className="text-danger mb-3">Access denied..</h1>
            <p className="text-muted text-center mb-4">
              You don't have permission to access this page.
              <br />
              Please check credentials and try again.
            </p>
          </>
        </div>
      </div>
    );
  };

  return (
    <>
      {layout ? (
        <Layout
          title={menuItems?.length ? "Choose a sidebar" : "Access Denied"}
          moduleCode={""}
        >
          <Info />
        </Layout>
      ) : (
        <Info />
      )}
    </>
  );
};

export default AccessDenied;
