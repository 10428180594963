import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthenticateContext } from "../../context/Auth";
import { Lang } from "../../components";
import CustomFormInput from "../../components/common/CustomFormInput";
import AuthLayout from "./components/AuthLayout";
import { postRequest } from "../../services/fetch";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButtonLabel } from "../../components/widgets/Loading";
import { PATH_DASHBOARD } from "../path";

function Login() {
  const [Authenticate] = useContext(AuthenticateContext);
  const methods = useForm();
  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = (data: any) => {
    handleLogin(data);
  };

  const handleLogin = (formData: any) => {
    setDisabled(true);
    postRequest({
      route: "auth/sign-in",
      data: formData,
      isPrivate: false,
    }).then((result) => {
      if (result.statusCode === 200) {
        const data = result?.data;
        localStorage.setItem("user", JSON.stringify(data));
        const modules = data?.modules;
        localStorage.setItem("modules", JSON.stringify(modules));
        if (data) {
          delete data.modules;
          localStorage.setItem("user", JSON.stringify(data));
        }
        window.location.href = PATH_DASHBOARD.root;
      } else {
        toast.error(result.message);
        setTimeout(() => {
          setDisabled(false);
        }, 300);
      }
    });
  };

  if (Authenticate.isAutharize) {
    return <Navigate to={"/dashboard"} />;
  }

  return (
    <AuthLayout subTitle={<Lang label="signInYourAcccount" />}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomFormInput
            required
            type="email"
            fieldName="email"
            label={<Lang label="email" />}
          />

          <CustomFormInput
            required
            type="password"
            fieldName="password"
            label={<Lang label="password" />}
          />

          <LoadingButtonLabel
            processing
            className="w-100"
            label={<Lang label="login" />}
            type={"submit"}
            loading={disabled}
            disabled={disabled}
          />
        </Form>
      </FormProvider>

      {/* <div className="d-flex justify-content-between mt-3">
        {process.env.REACT_APP_SIGNUP_ALLOW ? (
          <small>
            <span className="d-lg-inline-flex d-none pe-1">
              <Lang label="dontHaveAnAccount" />
            </span>
            <Link to={PATH_AUTH.register} className="text-primary">
              <Lang label="signUp" />
            </Link>
          </small>
        ) : null} 
        <div />

        <small>
          <Link to={PATH_AUTH.forgotPassword} className="text-primary">
            <Lang label="forgotPassword" />
          </Link>
        </small>
      </div> */}
    </AuthLayout>
  );
}

export default Login;
