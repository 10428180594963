import React from "react";
import { useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import cn from "classnames";
import { disableScroll } from "../../utils";

interface Props {
  type?: string;
  label: any;
  fieldName: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  pattern?: any;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  handleChange?: (data: any) => void;
  [key: string]: any; // allows dynamic keys and values
}

const CustomFormInput: React.FC<Props> = ({
  type = "text",
  label,
  fieldName,
  className,
  placeholder,
  required,
  disabled,
  readOnly,
  defaultValue,
  pattern,
  minValue,
  maxValue,
  minLength,
  maxLength,
  handleChange,
  ...props
}) => {
  const { register, setValue, formState } = useFormContext();
  const { errors }: any = formState;

  React.useEffect(() => {
    setValue(fieldName, defaultValue);
  }, [defaultValue, fieldName, setValue]);

  return (
    <Form.Group className="mb-3">
      {label && (
        <Form.Label className={cn(required && "required")}>{label}</Form.Label>
      )}
      <Form.Control
        type={type}
        readOnly={readOnly}
        // required={required}
        defaultValue={defaultValue}
        placeholder={placeholder}
        className={className}
        onWheel={Boolean(type === "number") ? disableScroll : () => {}}
        {...register(fieldName, {
          min: minValue,
          max: maxValue,
          minLength: minLength,
          maxLength: maxLength,
          pattern: pattern,
          disabled: disabled,
          required: required,
          // valueAsDate: Boolean(type === "date"),
          valueAsNumber: Boolean(type === "number"),
          onChange: handleChange ? (e: any) => handleChange(e) : () => {},
        })}
        isInvalid={Boolean(errors?.[fieldName])}
        {...props}
      />
    </Form.Group>
  );
};

export default CustomFormInput;
