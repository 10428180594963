import documentsRoutes from "./documents";
import imagesRoutes from "./images";
import videoRoutes from "./videos";

const mediaManagerRoutes: any = [];

mediaManagerRoutes.push(...documentsRoutes);
mediaManagerRoutes.push(...imagesRoutes);
mediaManagerRoutes.push(...videoRoutes);

export default mediaManagerRoutes;
