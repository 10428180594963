import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Page = React.lazy(() => import("./Page"));
const PageDetail = React.lazy(() => import("./components/Detail"));
const AddPage = React.lazy(() => import("./components/Add"));
const EditPage = React.lazy(() => import("./components/Edit"));

const pageRoute: any = [];
pageRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.content.page,
      element: <Page />,
    },
    {
      path: PATH_DASHBOARD.content.addPage,
      element: <AddPage />,
    },
    {
      path: PATH_DASHBOARD.content.editPage + "/:pageId",
      element: <EditPage />,
    },
    {
      path: PATH_DASHBOARD.content.pageDetail + "/:pageId",
      element: <PageDetail />,
    },
  ]
);

export default pageRoute;
