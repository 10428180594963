import React from "react";

interface variableProps {
  isSidebarActive: boolean;
  isList: boolean;
  isSKU: boolean;
  cartType: string;
}

const initData = {
  isSidebarActive: true,
  isList: false,
  isSKU: false,
  isCheckout: false,
  isPrint: false,
  isNext: false,
  cartType: "items",
};

const VariableContext = React.createContext<any>(initData);

function VariableProvider(props: any) {
  const [variable, setVariable] = React.useState<variableProps>(initData);

  const handleSetValues = () => {
    let items = localStorage.getItem("variables");
    let variableData = items ? JSON.parse(items) : initData;
    setVariable(variableData);
  };

  React.useEffect(() => {
    handleSetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VariableContext.Provider value={[variable, setVariable]}>
      {props.children}
    </VariableContext.Provider>
  );
}

export { VariableContext, VariableProvider };
