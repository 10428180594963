import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./routes/auth/Login";
import AppLoading from "./components/common/AppLoding";
import "react-toastify/dist/ReactToastify.css";
import dashboardRoute from "./routes/dashboard";
import accountRoutes from "./routes/account";
import appearanceRoutes from "./routes/preferences/appearance";
import prefeRoutes from "./routes/preferences";
import formRoutes from "./routes/forms";
import linksRoute from "./routes/Links";
import sectionRoute from "./routes/sections";
import roleRoutes from "./routes/role-management";
import roleMouduleRoutes from "./routes/roleModule";
import AccessDenied from "./routes/AccessDenied";
import pageRoute from "./routes/content/page";
import blogRoute from "./routes/content/blog/components";
import staffMemberRoutes from "./routes/staff-memeber";
import mediaManagerRoutes from "./routes/mediaManager";
import modulesRoute from "./routes/modules";
import settingRoutes from "./routes/setting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <AccessDenied />,
  },
  ...linksRoute,
  ...formRoutes,
  ...dashboardRoute,
  ...sectionRoute,
  ...roleRoutes,
  ...roleMouduleRoutes,
  ...pageRoute,
  ...blogRoute,
  ...accountRoutes,
  ...staffMemberRoutes,
  ...prefeRoutes,
  ...appearanceRoutes,
  ...mediaManagerRoutes,
  ...modulesRoute,
  ...settingRoutes,
]);

const App = () => {
  return (
    <React.Suspense fallback={<AppLoading />}>
      <RouterProvider router={router} />
    </React.Suspense>
  );
};

export default App;
