import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Links = React.lazy(() => import("./Links"));
const LinkDetail = React.lazy(() => import("./Detail"));

const linkRoute: any = [];

linkRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.links.link,
      element: <Links />,
    },
    {
      path: PATH_DASHBOARD.links.linkDetail + "/:menuId",
      element: <LinkDetail />,
    },
  ]
);

export default linkRoute;
