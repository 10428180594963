import React from "react";
import { PATH_DASHBOARD } from "../../path";

// const EditAbout = React.lazy(() => import("./Edit"));
const Appoinment = React.lazy(() => import("./Apppoinment"));
const AppoinmentDetail = React.lazy(() => import("./Detail"));
// const EnquiryAdd = React.lazy(() => import("./cpn/Form"));

const appoinmentRote: any = [];

appoinmentRote.push(
  ...[
    {
      path: PATH_DASHBOARD.forms.appoinment,
      element: <Appoinment />,
    },
    // {
    //   path: PD.Enquiry.addEnquiry,
    //   element: <EnquiryAdd />,
    // },
    // {
    //   path: PD.Enquiry.editEnquiry + "/:EnquiryId",
    //   element: <EnquiryAdd />,
    // },
    {
      path: PATH_DASHBOARD.forms.appoinmentDetail + "/:appoimmentId",
      element: <AppoinmentDetail />,
    },
  ]
);

export default appoinmentRote;
