import { AuthenticateProvider } from "./Auth";
import LanguageState from "./Lang";
import { ThemeProvider } from "./Theme";
import { VariableProvider } from "./Variable";

function Context({ children }: { children: any }) {
  return (
    <AuthenticateProvider>
      <VariableProvider>
        <LanguageState>
          <ThemeProvider>{children}</ThemeProvider>
        </LanguageState>
      </VariableProvider>
    </AuthenticateProvider>
  );
}
export default Context;
