import { useState, createContext, useEffect } from "react";

interface Authenticate {
  isAutharize: boolean;
  userData: Object;
  moduleData: [];
}

const contextDefaultValues: Authenticate = {
  isAutharize: false,
  userData: {},
  moduleData: [],
};

const AuthenticateContext = createContext<any>(contextDefaultValues);

function AuthenticateProvider(props: any) {
  const [authenticate, setAuthenticate] =
    useState<Authenticate>(contextDefaultValues);

  const handleSetRoleModule = (module: any) => {
    setAuthenticate({ ...authenticate, moduleData: module });
  };

  useEffect(() => {
    const tempUser = localStorage.getItem("user");
    const user = tempUser && JSON.parse(tempUser);
    user &&
      setAuthenticate({ isAutharize: true, userData: user, moduleData: [] });
  }, []);

  return (
    <AuthenticateContext.Provider
      value={[authenticate, setAuthenticate, handleSetRoleModule]}
    >
      {props.children}
    </AuthenticateContext.Provider>
  );
}

export { AuthenticateContext, AuthenticateProvider };
