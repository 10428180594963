import { Button, Col, Row, Spinner } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import cn from "classnames";

import "react-loading-skeleton/dist/skeleton.css";
import { Lang } from "..";
import { limit } from "../../services";

const borderRadius = 4;

export const GridLoading = ({ rowCount = 2 }: { rowCount?: number }) => {
  return (
    <>
      <Row>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={borderRadius}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={borderRadius}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={250}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>

        {rowCount !== 2 && (
          <Col>
            <Skeleton
              count={1}
              height={250}
              className="mb-4"
              borderRadius={borderRadius}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export const TableLoading = ({
  rowCount = limit,
  height = 44,
}: {
  rowCount?: number;
  height?: number;
}) => {
  return (
    <Skeleton
      count={rowCount}
      height={height}
      className="mb-0"
      borderRadius={0}
    />
  );
};

export const DetailLoading = () => {
  return (
    <>
      <Skeleton
        count={1}
        height={500}
        className="mb-4"
        borderRadius={borderRadius}
      />
      <Skeleton count={1} height={200} borderRadius={borderRadius} />
    </>
  );
};

export const DivLoading = ({ height = 230 }) => {
  return (
    <>
      <Skeleton count={1} height={height} borderRadius={borderRadius} />
    </>
  );
};

export const ProductCardLoading = ({
  height = 475,
  className,
}: {
  height?: number;
  className?: string;
}) => {
  return (
    <>
      <Skeleton
        count={1}
        height={height}
        borderRadius={4}
        className={className}
      />
    </>
  );
};

export const POSDetailLoading = () => {
  return (
    <>
      <Row>
        <Col md={2}>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
        <Col>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Skeleton
            count={1}
            height={200}
            className="mb-4"
            borderRadius={borderRadius}
          />
        </Col>
      </Row>
    </>
  );
};

export const ImageLoading = () => {
  return (
    <Skeleton count={1} height={180} width={180} borderRadius={borderRadius} />
  );
};

export const LoadingButtonLabel = ({
  label,
  type = "submit",
  disabled,
  loading = disabled,
  processing,
  className,
  handleClick,
}: {
  label: any;
  type: "button" | "submit" | "reset";
  disabled: boolean;
  loading?: boolean;
  processing?: boolean;
  className?: string;
  handleClick?: () => void;
}) => {
  return (
    <Button
      type={type}
      variant="primary"
      className={cn("text-white px-4", className)}
      disabled={disabled}
      onClick={handleClick}
    >
      {loading ? (
        <>
          <Spinner
            animation="border"
            role="status"
            size="sm"
            className="me-1"
          />
          {processing && <Lang label="processing" />}
        </>
      ) : (
        label
      )}
    </Button>
  );
};
