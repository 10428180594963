import { Container } from "react-bootstrap";
import LangDropdown from "../dropdown/LangDropdown";
import { CONTAINER_FLUID } from "../../services";
import AppLoading from "../common/AppLoding";
import { AuthenticateContext } from "../../context/Auth";
import { useContext } from "react";

const Content = ({ open, onOpenSidebar, children, title, ...props }: any) => {
  const [authtenticate] = useContext(AuthenticateContext);
  return (
    <section className="home-section app">
      <div className="header-section page-title d-flex align-items-center justify-content-between shadow-sm">
        <div className="d-flex align-items-center">
          <i
            className="bx bx-menu p-3"
            open={open}
            onClick={onOpenSidebar}
            {...props}
          ></i>
          <span className="text">{title}</span>
        </div>

        <div className="d-flex">
          <LangDropdown />
        </div>
      </div>

      <Container fluid={CONTAINER_FLUID} className="p-0">
        {authtenticate?.moduleData === null ? (
          <AppLoading />
        ) : (
          <div className="p-4">{children}</div>
        )}
      </Container>
    </section>
  );
};

export default Content;
