import React from "react";
import { PATH_DASHBOARD } from "../path";

const Modules = React.lazy(() => import("./Modules"));

const modulesRoute: any = [];
modulesRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.configuration.modules,
      element: <Modules />,
    },
  ]
);

export default modulesRoute;
