import React from "react";
import { PATH_DASHBOARD as PD } from "../../../routes/path";

// appearance
const Appearance = React.lazy(() => import("./Appearance"));

const appearanceRoutes: any = [];

// if (isAllowed("ADMIN_PREF", "isReadable")) {
appearanceRoutes.push({
  path: PD.preferences.appearance,
  element: <Appearance />,
});
// }

export default appearanceRoutes;
