import React from "react";
import { Image } from "react-bootstrap";
import { AuthenticateContext } from "../../context/Auth";
import { RES_API_URL } from "../../services";

interface AppLogoProps {
  alt?: any;
  width?: any;
  height?: any;
  className?: any;
  style?: any;
  onClick?: () => void;
}

const AppLogo: React.FC<AppLogoProps> = ({
  alt,
  width = 40,
  height,
  className,
  style,
  onClick,
}) => {
  const { user } = React.useContext(AuthenticateContext);
  const defaultLogo = user?.data?.businessLogoOnly?.url;

  return (
    <Image
      alt={alt}
      title={alt}
      width={width}
      height={height}
      className={className}
      style={style}
      onClick={onClick}
      src={
        defaultLogo
          ? RES_API_URL + defaultLogo
          : "/images/logo-only-transparent.png"
      }
    />
  );
};

export default AppLogo;
