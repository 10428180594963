import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import swal from "sweetalert";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
//Date Variable
const defaultTimeZone = "Asia/Yangon";
//const dateTimeFormat = 'hh:mm A - DD-MM-YYYY'
const dateTimeFormat = "YYYY-MM-DD hh:mm A";
const dateFormat = "DD-MM-YYYY";
const dateFormat2 = "YYYY-MM-DD";
const dateFormat3 = "D MMM YYYY";
const dateFormat4 = "D";
const _limit: any = process.env.REACT_APP_PAGINATION_LIMIT_LIST;
const USER = "user";

export { dateFormat2 };
export { dateFormat3 };
export { dateFormat4 };

export const handleClose: any = (setShow: any) => setShow(false);
export const handleShow = (setShow: any) => setShow(true);

export const getNumber = (index: number, skip: number) => {
  return index + 1 + skip * _limit;
};

export const handleDateFormat = (date: any, format?: string) => {
  return dayjs(date)
    .tz(defaultTimeZone)
    .format(format || dateFormat);
};

export const handleDateTimeFormat = (date: any) => {
  return dayjs(date).tz(defaultTimeZone).format(dateTimeFormat);
};

export function numberFormat(x: any) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
}

export function toFloat(x: any) {
  return x
    ? parseFloat(x)
        .toFixed(3)
        .replace(/\.?0+$/, "")
    : 0;
}

export const login = (user: any) => {
  localStorage.setItem(USER, user);
};

export const logout = () => {
  localStorage.removeItem(USER);
};

export const isLogin = () => {
  if (localStorage.getItem(USER)) {
    return true;
  }
  return false;
};

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur();
};

export const handleKeyDown = (event: any) => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const inputChangeService = (
  evt: any,
  formData: any,
  setFormData: any,
  wantString?: boolean
) => {
  const target = evt.target;
  const name = target.id;
  let value;

  if (target.type === "checkbox") {
    value = target.checked;
  } else if (target.type === "number" || target.type === "select-one") {
    if (target.value === "") {
      value = "";
    } else {
      value = target.value;
      if (wantString) {
        value = target.value;
      } else {
        value = parseFloat(target.value);
      }
    }
  } else {
    value = target.value;
  }
  setFormData({
    ...formData,
    [name]: value,
  });
};

export const getStoreUser = () => {
  const tempUser = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  return user;
};

export const handleDelete = () => {
  swal({
    title: "Are you sure to delete?",
    text: "Once deleted, you will not be able to recover this imaginary product!",
    icon: "warning",
    buttons: [true, true],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      swal("Poof! Your product has been deleted!", {
        icon: "success",
        buttons: [false],
        timer: 1500,
      });
    }
  });
};

export const openUrl = (link: string) => {
  // window.open('https://facebook.com/mgmg', '_blank');
  window.open(link, "_blank");
};

export const scrollToTop = () => {
  window &&
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
};
