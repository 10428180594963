import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Images = React.lazy(() => import("./Images"));

const imagesRoutes: any = [];

imagesRoutes.push(
  ...[
    {
      path: PATH_DASHBOARD.mediaManager.image,
      element: <Images />,
    },
  ]
);

export default imagesRoutes;
