import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Contact = React.lazy(() => import("./Contact"));

const contactRoute: any = [];

contactRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.forms.contact,
      element: <Contact />,
    },
  ]
);

export default contactRoute;
