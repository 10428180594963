import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Menu = React.lazy(() => import("./Menus"));
const MenuDetail = React.lazy(() => import("./Detail"));
const AddMenu = React.lazy(() => import("./Add"));

const menuRoute: any = [];
menuRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.links.menu,
      element: <Menu />,
    },
    {
      path: PATH_DASHBOARD.links.addMenu,
      element: <AddMenu />,
    },
    {
      path: PATH_DASHBOARD.links.menuDetail + "/:menuId",
      element: <MenuDetail />,
    },
  ]
);

export default menuRoute;
