import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { actionData, getMethod, getPersons } from "./api";
import swal from "sweetalert";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
//Date Variable
const defaultTimeZone = "Asia/Yangon";
//const dateTimeFormat = 'hh:mm A - DD-MM-YYYY'
const dateTimeFormat = "YYYY-MM-DD hh:mm A";
const dateFormat = "DD-MM-YYYY";
const dateFormat2 = "YYYY-MM-DD";
export const THEME_MODE = process.env.REACT_APP_THEME_MODE;
export const THEME_COLOR = process.env.REACT_APP_THEME_COLOR;
const USER = "user";
const MODULES = "modules";
export const RES_API_URL = process.env.REACT_APP_RES_API_URL;
export { dateFormat2 };
export const limit: number = Number(process.env.REACT_APP_PAGINATION_LIMIT);
export const APP_LOGO = process.env.REACT_APP_APP_LOGO || "logo.png";
export const APP_TITLE = process.env.REACT_APP_APP_TITLE || "Elefant Tech";
export const CONTAINER_FLUID = process.env.REACT_APP_CONTAINER_FLUID || "xs";
export const handleClose: any = (setShow: any) => {
  setShow(false);
};
export const handleShow = (setShow: any) => setShow(true);

export const handleDateFormat = (date: any, format?: string) => {
  return dayjs(date)
    .tz(defaultTimeZone)
    .format(format || dateFormat);
};
export const handleDateFormat2 = (date: any, format?: string) => {
  // return dayjs(date)
  //   .tz(defaultTimeZone)
  //   .format(format || dateFormat)

  const inputDate = new Date(date);
  const strDate = `${inputDate.getDate()} ${inputDate.toLocaleString(
    "default",
    {
      month: "short",
    }
  )} ${inputDate.getFullYear()}`;
  return strDate;
};
export const getSearchParam = (key: any) => {
  const params: any = new URLSearchParams(window.location.search);
  const param = params.get(key) ?? null;
  return param;
};
export const getRowNumber = (count: number) => {
  const page = getSearchParam("page") ? parseInt(getSearchParam("page")) : 1;
  return (page > 1 ? page - 1 : 0) * limit + count + 1;
};

export const isValidDate = (dateString: any) => {
  var regEx = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
  var regEx2 = /^\d{4}-\d{2}-\d{2}$/;
  return (
    dateString?.match(regEx) !== null || dateString?.match(regEx2) !== null
  );
};

export const handleDateTimeFormat = (date: any) => {
  return dayjs(date).tz(defaultTimeZone).format(dateTimeFormat);
};

export function numberFormat(x: any) {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
}

export function toFloat(x: any) {
  return x
    ? parseFloat(x)
        .toFixed(3)
        .replace(/\.?0+$/, "")
    : 0;
}

export const login = (user: any) => {
  localStorage.setItem(USER, user);
};

export const logout = () => {
  localStorage.removeItem(USER);
  localStorage.removeItem(MODULES);
};

export const getStoreUser = () => {
  const tempUser = localStorage.getItem("user");
  const user = tempUser && JSON.parse(tempUser);
  return user;
};
export const getStoreModules = () => {
  const tempModules = localStorage.getItem("modules");
  const modules = tempModules && JSON.parse(tempModules);
  return modules;
};
export const randomID = (length = 8) => {
  // Declare all characters
  let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  // Pick characers randomly
  let str: any = "";
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  if (str.length === length) {
    str = str.match(/(.{1,4})/g).join("-");
    return str;
  }

  return;
};
export const isLogin = () => {
  if (localStorage.getItem(USER)) {
    return true;
  } else {
    return false;
  }
};

//For Number Input
export const disableScroll = (e: any) => {
  e.currentTarget.blur();
};

export const handleKeyDown = (event: any) => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const inputChangeService = (
  evt: any,
  formData: any,
  setFormData: any,
  wantString?: boolean
) => {
  const target = evt.target;
  const name = target.id;
  let value;

  if (target.type === "checkbox") {
    value = target.checked;
  } else if (target.type === "number" || target.type === "select-one") {
    if (target.value === "") {
      value = "";
    } else {
      value = target.value;
      if (wantString) {
        value = target.value;
      } else {
        value = parseFloat(target.value);
      }
    }
  } else {
    value = target.type === "text" ? target.value : target.value;
  }

  setFormData({
    ...formData,
    [name]: value,
  });
};

export const getPersonsService = async (
  keyword?: string,
  setOwners?: any,
  setBrokers?: any
) => {
  actionData(getMethod, getPersons(0, keyword)).then((result) => {
    let tempOwner: any = [];
    let tempBroker: any = [];

    result.data.forEach((data: any) => {
      tempOwner.push({
        value: data.owners.id,
        label: `${data.fullName} (${data.phone})`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });

      tempBroker.push({
        value: data.owners.id,
        label: `${data.fullName} (${data.phone})`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });
    });

    setOwners && setOwners(tempOwner);
    setBrokers && setBrokers(tempBroker);
  });
};

export const handelGetPersons = async (keyword: string, setPersons: any) => {
  actionData(getMethod, getPersons(0, keyword)).then((result) => {
    let temp: any = [];

    result.data.forEach((data: any) => {
      temp.push({
        value: data.id,
        label: `${data.fullName} (${data.phone})`,
        fullName: data.fullName,
        phone: data.phone,
        phoneOther: data.phoneOther,
        email: data.email,
        address: data.address,
      });
    });

    setPersons(temp);
  });
};

export const handleDelete = () => {
  swal({
    title: "Are you sure to delete?",
    text: "Once deleted, you will not be able to recover this imaginary product!",
    icon: "warning",
    buttons: [true, true],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      swal("Poof! Your product has been deleted!", {
        icon: "success",
        buttons: [false],
        timer: 1500,
      });
    }
  });
};

export const openUrl = (link: string) => {
  // window.open('https://facebook.com/mgmg', '_blank');
  window.open(link, "_blank");
};
export const defineAccess = (array: any) => {
  return array.some((item: any) => item === userLevel());
};

export const userLevel = () => {
  let role = getStoreUser()?.roleName;
  let level = 6;
  if (role === "Owner" || role === "Technical") level = 1;
  if (role === "Admin") level = 2;
  if (role === "Manager") level = 3;
  if (role === "ATs/SATs/Ts") level = 4;
  if (role === "Operator") level = 5;
  return level;
};
export const formDataService = ({
  formData,
  sendNull = false,
}: {
  formData: any;
  sendNull?: boolean;
}) => {
  let data: any = {};

  for (let [key, value] of Object.entries(formData)) {
    if (sendNull) {
      if (typeof value === "string") {
        if (value === "") {
          data[key] = null;
        } else {
          data[key] = value.trim();
        }
      } else {
        if (value === "") {
          data[key] = null;
        } else {
          data[key] = value;
        }
      }
    } else {
      if (value !== null && value !== "") {
        if (typeof value === "string") {
          data[key] = value.trim();
        } else {
          data[key] = value;
        }
      }
    }
  }
  return data;
};
export const checkRequired = (data: any, required: any) => {
  let isReqiured = false;
  for (const field of required) {
    if (data.hasOwnProperty(field) === false) {
      isReqiured = true;
    }
  }
  return isReqiured;
};

export const isAllowed = (mCode: any, fCode: any) => {
  let module: any = false;
  const modules = getStoreModules() || [];
  module = modules?.find(
    (module: any) => module?.code === mCode && module?.[fCode]
  );
  return module !== undefined ? true : false;
};
export function formatModules(array: any) {
  let newArray = [];
  for (const arr of array) {
    const { module, read } = arr;
    if (read) {
      newArray.push(module);
    }
  }
  return newArray;
}
