import React from "react";
import { PATH_DASHBOARD as PD } from "../../routes/path";
import { isAllowed } from "../../services";

// role management
const Role = React.lazy(() => import("./RoleManagement"));

const roleRoutes: any = [];
if (isAllowed("ROLE", "published")) {
  roleRoutes.push({
    path: PD.configuration.role,
    element: <Role />,
  });
}
export default roleRoutes;
