import {
  Accordion,
  Button,
  Image,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useContext } from "react";
import { LangContext } from "../context/Lang";
import { TableLoading } from "./widgets/Loading";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft, BsPencil, BsTrash } from "react-icons/bs";

export const NoData = ({
  title = "data",
  noImage = false,
}: {
  title?: string;
  noImage?: boolean;
}) => {
  return (
    <>
      <section className="d-flex flex-center mt-5">
        {!noImage && (
          <>
            <Image
              src={"images/no_data.png"}
              width={200}
              className="rounded mb-4"
            />
          </>
        )}
      </section>
      <p className="mb-0 d-flex flex-center">No {title} found!</p>
    </>
  );
};
export const GoBack = ({ label }: { label: any }) => {
  const history = useNavigate();
  return (
    <section className="cursor" onClick={() => history(-1)}>
      <p className="mb-0 fw-bold">
        <BsChevronLeft /> <span className="ps-1">{label}</span>
      </p>
    </section>
  );
};

export const Lang = ({ label }: { label: string }) => {
  const {
    dispatch: { translate },
  } = useContext(LangContext);
  return <>{translate(label)}</>;
};

const TableLayout = ({
  loading,
  children,
  page,
}: {
  loading: boolean;
  children: any;
  page?: any;
  setPage?: any;
}) => {
  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <section className="bg-card mb-4 p-4">
          <Table responsive className={page ? "mb-4" : "mb-0"} striped>
            {children}
          </Table>
        </section>
      )}
    </>
  );
};
export default TableLayout;

export const TableRowButtons = ({
  item,
  showForm,
  handleDelete,
}: {
  item: any;
  showForm?: any;
  handleDelete: any;
  flex?: string;
}) => (
  <span className="flex-end">
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <Lang label="edit" />
        </Tooltip>
      }
    >
      <Button className="btn-sm bg-grad ms-2" onClick={() => showForm(item)}>
        <BsPencil />
      </Button>
    </OverlayTrigger>
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>
          <Lang label="delete" />
        </Tooltip>
      }
    >
      <Button
        className="bg-danger btn-sm ms-2"
        onClick={() => handleDelete(item)}
      >
        <BsTrash />
      </Button>
    </OverlayTrigger>
  </span>
);

export const FieldAccorion = ({
  label,
  children,
}: {
  label: any;
  children: any;
}) => {
  return (
    <Accordion className="mb-4" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="">{label}</Accordion.Header>
        <Accordion.Body>
          <Row className="p-3">{children}</Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
