import React from "react";
import { PATH_DASHBOARD as PD } from "../../routes/path";

// preferences
import appearanceRoutes from "./appearance";

const Preferences = React.lazy(() => import("./Preferences"));

const prefeRoutes: any = [];

// if (isAllowed("ADMIN_PREF", "isReadable")) {
prefeRoutes.push(
  ...[
    {
      path: PD.preferences.root,
      element: <Preferences />,
    },
    ...appearanceRoutes,
  ]
);
// }

export default prefeRoutes;
