import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Video = React.lazy(() => import("./Video"));

const videoRoutes: any = [];

videoRoutes.push(
  ...[
    {
      path: PATH_DASHBOARD.mediaManager.video,
      element: <Video />,
    },
  ]
);

export default videoRoutes;
