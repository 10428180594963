function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";
const ROOTS_FORMS = "/forms";
const ROOTS_LINKS = "/link";
const ROOTS_MEDIA_MANAGER = "/media-manager";
const ROOTS_SECTION = "/section";
const ROOTS_CONTENT = "/content";
const ROOTS_ROLEMODULE = "/role-module";
const ROOTS_ROLE = `/roles`;

const ROOTS_ACCOUNT = `/account`;
const ROOTS_USERS = `/users`;
const ROOTS_ABOUT = `/about`;
const ROOTS_ClEINT = `/clients`;
const ROOTS_PRODUCT = `/products`;
const ROOTS_SERVICE = `/services`;
const ROOTS_PRODUCT_TYPE = `/product-type`;
const ROOTS_PREFE = `/preferences`;
const ROOTS_SETTING = `/setting`;
const ROOTS_CONFIG = `/configuration`;

// auth path
export const PATH_AUTH = {
  root: "/",
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  forgotPassword: path(ROOTS_AUTH, "/forgot-password"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

// account path
export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  profile: path(ROOTS_ACCOUNT, "/profile"),
  businessProfile: path(ROOTS_ACCOUNT, "/business-profile"),
  changePassword: path(ROOTS_ACCOUNT, "/change-password"),
};

// staff
export const PATH_STAFFS = {
  root: ROOTS_USERS,
  staffDetail: path(ROOTS_USERS, "/detail"),
  addStaff: path(ROOTS_USERS, "/add-new"),
  editStaff: path(ROOTS_USERS, "/edit"),
};

// dashboard path
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  roleModule: {
    root: ROOTS_ROLEMODULE,
    // editAbout: path(ROOTS_ABOUT, "/edit"),
  },
  role: {
    root: ROOTS_ROLE,
    // editAbout: path(ROOTS_ABOUT, "/edit"),
  },
  about: {
    root: ROOTS_ABOUT,
    editAbout: path(ROOTS_ABOUT, "/edit"),
  },
  client: {
    root: ROOTS_ClEINT,
    editClient: path(ROOTS_ClEINT, "/edit-client"),
    addClient: path(ROOTS_ClEINT, "/add-client"),
    detialClient: path(ROOTS_ClEINT, "/detail"),
  },
  product: {
    root: ROOTS_PRODUCT,
    editProduct: path(ROOTS_PRODUCT, "/edit-product"),
    addProduct: path(ROOTS_PRODUCT, "/add-product"),
    detialProduct: path(ROOTS_PRODUCT, "/detail"),
  },
  services: {
    root: ROOTS_SERVICE,
  },
  productType: {
    root: ROOTS_PRODUCT_TYPE,
  },
  forms: {
    root: ROOTS_FORMS,

    contact: path(ROOTS_FORMS, "/contact"),
    contactDetail: path(ROOTS_FORMS, "/contact/detail"),
    addContact: path(ROOTS_FORMS, "/contact/add-new"),
    editeContact: path(ROOTS_FORMS, "/contact/edit"),

    appoinment: path(ROOTS_FORMS, "/appoinment"),
    appoinmentDetail: path(ROOTS_FORMS, "/appoinment/detail"),
    addAppoinment: path(ROOTS_FORMS, "/appoinment/add-new"),
    editAppoinment: path(ROOTS_FORMS, "/appoinment/edit"),

    enquiry: path(ROOTS_FORMS, "/enquiry"),
    enquiryDetail: path(ROOTS_FORMS, "/enquiry/detail"),
    addEnquiry: path(ROOTS_FORMS, "/enquiry/add-new"),
    editeEquiry: path(ROOTS_FORMS, "/enquiry/edit"),
  },
  links: {
    root: ROOTS_LINKS,

    link: path(ROOTS_LINKS, "/siteMap"),
    linkDetail: path(ROOTS_LINKS, "/siteMap/detail"),
    addLink: path(ROOTS_LINKS, "/siteMap/add-new"),
    editLink: path(ROOTS_LINKS, "/siteMap/edit"),

    menu: path(ROOTS_LINKS, "/menu"),
    menuDetail: path(ROOTS_LINKS, "/menu/detail"),
    addMenu: path(ROOTS_LINKS, "/menu/add-new"),
    editMenu: path(ROOTS_LINKS, "/menu/edit"),
  },
  section: {
    root: ROOTS_SECTION,

    sections: path(ROOTS_SECTION, "/sections"),
    sectionsDetail: path(ROOTS_SECTION, "/sections/detail"),
    addSection: path(ROOTS_SECTION, "/sections/add-new"),
    editSection: path(ROOTS_SECTION, "/sections/edit"),

    sectionDestign: path(ROOTS_SECTION, "/section-design"),
    sectionDestignDetail: path(ROOTS_SECTION, "/section-design/detail"),
    addsectionDestign: path(ROOTS_SECTION, "/section-design/add-new"),
    editsectionDestign: path(ROOTS_SECTION, "/section-design/edit"),
  },
  mediaManager: {
    root: ROOTS_MEDIA_MANAGER,

    video: path(ROOTS_MEDIA_MANAGER, "/video"),
    videoDetail: path(ROOTS_MEDIA_MANAGER, "/video/detail"),
    addVideo: path(ROOTS_MEDIA_MANAGER, "/video/add-new"),
    editVideo: path(ROOTS_MEDIA_MANAGER, "/video/edit"),

    document: path(ROOTS_MEDIA_MANAGER, "/document"),
    documentDetail: path(ROOTS_MEDIA_MANAGER, "/document/detail"),
    addDocument: path(ROOTS_MEDIA_MANAGER, "/document/add-new"),
    editDocument: path(ROOTS_MEDIA_MANAGER, "/document/edit"),

    image: path(ROOTS_MEDIA_MANAGER, "/image"),
    imageDetail: path(ROOTS_MEDIA_MANAGER, "/image/detail"),
    addImage: path(ROOTS_MEDIA_MANAGER, "/image/add-new"),
    editImage: path(ROOTS_MEDIA_MANAGER, "/image/edit"),
  },
  content: {
    root: ROOTS_CONTENT,

    page: path(ROOTS_CONTENT, "/page"),
    pageDetail: path(ROOTS_CONTENT, "/page/detail"),
    addPage: path(ROOTS_CONTENT, "/page/add-new"),
    editPage: path(ROOTS_CONTENT, "/page/edit"),

    blog: path(ROOTS_CONTENT, "/blogs"),
    blogDetail: path(ROOTS_CONTENT, "/blogs/detail"),
    addBlog: path(ROOTS_CONTENT, "/blogs/add-new"),
    editBlog: path(ROOTS_CONTENT, "/blogs/edit"),

    news: path(ROOTS_CONTENT, "/news"),
    newsDetail: path(ROOTS_CONTENT, "/news/detail"),
    addNews: path(ROOTS_CONTENT, "/news/add-new"),
    editNews: path(ROOTS_CONTENT, "/news/edit"),
  },
  preferences: {
    root: ROOTS_PREFE,
    appearance: path(ROOTS_PREFE, "/appearance"),
  },
  setting: {
    root: ROOTS_SETTING,
  },
  configuration: {
    root: ROOTS_CONFIG,
    role: path(ROOTS_CONFIG, "/roles"),
    modules: path(ROOTS_CONFIG, "/modules"),
    rolePermisson: path(ROOTS_CONFIG, "/role-permission"),
  },
};
