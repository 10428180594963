import React from "react";
import { THEME_COLOR, THEME_MODE } from "../services";

interface ThemeProps {
  themeMode: any;
  themeColor: any;
  colors: any;
}

const initTheme = {
  themeMode: THEME_MODE || "light",
  themeColor: THEME_COLOR || "blue",
  colors: {
    blue: "blue",
    green: "green",
    red: "red",
    orange: "orange",
    purple: "purple",
  },
};

export const ThemeContext = React.createContext<any>(initTheme);

export const ThemeProvider = (props: any) => {
  const [theme, setTheme] = React.useState<ThemeProps>(initTheme);

  const switchTheme = (theme: any) => {
    const newTheme = {
      themeMode: theme ? theme?.themeMode : initTheme.themeMode,
      themeColor: theme
        ? theme?.themeColor || theme.themeColor
        : initTheme.themeColor,
    };

    setTheme({ ...newTheme, colors: initTheme.colors });

    let newData: any = localStorage.getItem("user");
    newData = JSON.parse(newData);
    newData.theme = newTheme;

    localStorage.setItem("user", JSON.stringify(newData));
    document.documentElement.setAttribute(
      "data-theme",
      `${newTheme.themeMode}-${newTheme.themeColor}`
    );
  };

  React.useEffect(() => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const themeData = user?.theme;
      switchTheme(themeData ? themeData : null);
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
