import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Enquiry = React.lazy(() => import("./Enquiry"));
const EnquiryDetail = React.lazy(() => import("./Detail"));

const enquiryRoute: any = [];

enquiryRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.forms.enquiry,
      element: <Enquiry />,
    },
    {
      path: PATH_DASHBOARD.forms.enquiryDetail + "/:enquiryId",
      element: <EnquiryDetail />,
    },
  ]
);

export default enquiryRoute;
