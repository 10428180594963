import React from "react";
import { PATH_DASHBOARD } from "../../path";

const Documents = React.lazy(() => import("./Documents"));

const documentsRoutes: any = [];

documentsRoutes.push(
  ...[
    {
      path: PATH_DASHBOARD.mediaManager.document,
      element: <Documents />,
    },
  ]
);

export default documentsRoutes;
