import { getStoreUser } from ".";

const API_URL = process.env.REACT_APP_API_URL;
const user = getStoreUser();

export const requestData = async ({
  method,
  route,
  data,
  token,
}: {
  method: string;
  route: string;
  data?: any;
  token?: string;
}) => {
  const header: any = token
    ? { "Content-type": "application/json", Authorization: `Bearer ${token}` }
    : { "Content-type": "application/json" };

  try {
    const response = await fetch(`${API_URL}/${route}`, {
      method: method,
      headers: header,
      mode: "cors",
      cache: "default",
      body: data && JSON.stringify(data),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    return console.log("Something wrong!");
  }
};

export const privateRequest = async ({
  method,
  route,
  data,
}: {
  method: string;
  route: string;
  data: any;
}) => {
  const { accessToken } = await requestData({
    method: "POST",
    route: "auth/token",
    data: { token: user?.refreshToken },
  });

  return await requestData({
    method: method,
    route: route,
    data: data,
    token: accessToken,
  });
};

export const generateRequest = async ({
  method,
  route,
  data,
  isPrivate,
}: {
  method: string;
  route: string;
  data: any;
  isPrivate: boolean;
}) => {
  return isPrivate
    ? privateRequest({
        method: method,
        route: route,
        data: data,
      })
    : requestData({
        method: method,
        route: route,
        data: data,
      });
};

//For Get Method
export const getRequest = async ({
  route,
  data,
  isPrivate = true,
}: {
  route: any;
  data?: any;
  isPrivate?: boolean;
}) => {
  return generateRequest({
    method: "GET",
    route: route,
    data: data,
    isPrivate: isPrivate,
  });
};

export const postRequest = async ({
  route,
  data,
  isPrivate = true,
}: {
  route: string;
  data: any;
  isPrivate?: boolean;
}) => {
  return await generateRequest({
    method: "POST",
    route: route,
    data: data,
    isPrivate: isPrivate,
  });
};

export const putRequest = async ({
  route,
  data,
  isPrivate = true,
}: {
  route: string;
  data: any;
  isPrivate?: boolean;
}) => {
  return await generateRequest({
    method: "PUT",
    route: route,
    data: data,
    isPrivate: isPrivate,
  });
};

export const deleteRequest = async ({
  route,
  data,
  isPrivate = true,
}: {
  route: string;
  data?: any;
  isPrivate?: boolean;
}) => {
  return generateRequest({
    method: "DELETE",
    route: route,
    data: data,
    isPrivate: isPrivate,
  });
};
