import React from "react";
import { PATH_DASHBOARD } from "../../path";
import { isAllowed } from "../../../services";

const Section = React.lazy(() => import("./Section"));
const SectionDetail = React.lazy(() => import("./Detail"));
const AddSection = React.lazy(() => import("./Add"));
const EditSection = React.lazy(() => import("./Edit"));

const sectionsRoute: any = [];
if (isAllowed("STNS", "published")) {
  sectionsRoute.push(
    ...[
      {
        path: PATH_DASHBOARD.section.sections,
        element: <Section />,
      },

      {
        path: PATH_DASHBOARD.section.addSection,
        element: <AddSection />,
      },
      {
        path: PATH_DASHBOARD.section.editSection + "/:sectionId",
        element: <EditSection />,
      },
      {
        path: PATH_DASHBOARD.section.sectionsDetail + "/:sectionId",
        element: <SectionDetail />,
      },
    ]
  );
}
export default sectionsRoute;
