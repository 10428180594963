import React from "react";
import { PATH_STAFFS as PS } from "../../routes/path";
import { isAllowed } from "../../services";

// staffs member of organization
const StaffMember = React.lazy(() => import("./User"));
const staffMemberRoutes: any = [];

if (isAllowed("USR", "published")) {
  staffMemberRoutes.push({
    path: PS.root,
    element: <StaffMember />,
  });
}

export default staffMemberRoutes;
