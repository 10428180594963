import { createRoot } from "react-dom/client";
import App from "./App";
import Context from "./context";
import reportWebVitals from "./reportWebVitals";
import "./assets/scss/index.scss";
import { ToastContainer } from "react-toastify";
import InternetStatus from "./components/widgets/InternetStatus";

const container: any = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <StrictMode>
  <Context>
    <ToastContainer autoClose={1500} />
    <InternetStatus />
    <App />
  </Context>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
