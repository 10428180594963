import appoinmentRote from "./appoinment";
import contactRoute from "./contact";
import enquiryRoute from "./enquiry";

const formRoutes: any = [];

formRoutes.push(...enquiryRoute);
formRoutes.push(...contactRoute);
formRoutes.push(...appoinmentRote);

export default formRoutes;
