import React from "react";
import { Image } from "react-bootstrap";
import { APP_LOGO, APP_TITLE } from "../../../services";

// props
export declare interface appLogoProps {
  hideLogo?: true;
  subTitle: string | any;
  children?: any;
}

const AuthLayout: React.FC<appLogoProps> = ({
  subTitle,
  hideLogo,
  children,
}) => {
  return (
    <div className="auth-bg">
      <div className="auth-container shadow-sm">
        <div className="text-center mb-4">
          {!hideLogo && <Image src={APP_LOGO} width={80} />}
          <h4 className="fw-bold logo-name my-2">{APP_TITLE}</h4>
          <small>{subTitle}</small>
        </div>
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
