import React from "react";
import { Button, Dropdown, Image } from "react-bootstrap";
import { LangContext } from "../../context/Lang";

interface LangDropdownProps {}

const langData = [
  {
    id: 1,
    lang: "EN",
    label: "English",
    icon: "flag-eng.svg",
  },
  {
    id: 2,
    lang: "MM",
    label: "Myanmar",
    icon: "flag-myanmar.svg",
  },
  {
    id: 3,
    lang: "TH",
    label: "Thailand",
    icon: "flag-thailand.svg",
  },
];

const LangDropdown: React.FC<LangDropdownProps> = () => {
  const {
    state: { language },
    dispatch: { setLanguage },
  } = React.useContext(LangContext);

  const [selected, setSelected] = React.useState<any>();

  const handleChangeLanguage = React.useCallback(
    (value: any) => {
      setLanguage(value?.lang);
      setSelected(value);
    },
    [setLanguage]
  );

  React.useEffect(() => {
    language &&
      handleChangeLanguage(
        langData.find((locale: any) => locale?.lang === language)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center me-3 lg-dropdown">
      <Dropdown className="lang-dropdown">
        <Dropdown.Toggle
          variant="light"
          className="bg-transparent border-0"
          id="language"
        >
          <Image
            src={`/images/lang/${selected?.icon}`}
            width="25"
            className="me-2"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {langData?.map((lang: any, index: number) => (
            <Dropdown.Item
              key={index}
              as={Button}
              active={language === lang?.lang}
              className={language === lang?.lang ? " pointer-event-none" : ""}
              onClick={() => handleChangeLanguage(lang)}
            >
              {lang.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LangDropdown;
