import React from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { BsFillCircleFill } from "react-icons/bs";

import AppLogo from "../../common/AppLogo";
import { APP_TITLE } from "../../../services";
import AvatarDropdown from "../../dropdown/AvatarDropdown";
import menuItems from "./Menus";
import { ThemeContext } from "../../../context/Theme";

interface LeftSidebarProps {
  open: boolean;
}

const LeftSidebar: React.FC<LeftSidebarProps> = ({ open }) => {
  const { pathname } = useLocation();
  const { theme } = React.useContext(ThemeContext);
  const [lightMode, setLightMode] = React.useState(true);
  React.useEffect(() => {
    const newMode = theme?.themeMode === "light";
    setLightMode(newMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sortedMenuItems = React.useMemo(() => {
    const sortedItems = menuItems
      .map((item: any) => {
        if (item.subMenu) {
          item.subMenu.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
        }
        return item;
      })
      .sort((a: any, b: any) => a.sortOrder - b.sortOrder);
    return sortedItems;
  }, []);

  return (
    <div className={`sidebar shadow ${open ? "" : "close"}`}>
      <div className="logo-details">
        <AppLogo width={42} className="ms-3" />
        <span className="logo-name mx-2 text-truncate text">
          {/* {user?.data?.organization?.defaultLogo === "logo_image" ? (
              <AppLogoImage height={42} width={120} className="img-fluid" />
            ) : ( */}
          {APP_TITLE}
          {/* )} */}
        </span>
      </div>

      <Sidebar
        collapsed={!open}
        breakPoint="xs"
        width="unset"
        backgroundColor={lightMode ? "initial" : "var(--erv-secondary)"}
        rootStyles={{
          color: lightMode ? "#333" : "rgba(255,255,255,0.7)",
          ".ps-menu-button": {
            color: lightMode ? "initial" : "rgba(255,255,255,0.7)",
            height: "44px",
            backgroundColor: lightMode ? "initial" : "var(--erv-secondary)",
            "&:hover": {
              backgroundColor: lightMode ? "#f7f8f9" : "var(--erv-primary)",
              color: lightMode ? "initial" : "rgba(255,255,255,0.6)",
            },
          },
          ".ps-menu-button.ps-active": {
            color: "#fff",
            backgroundColor: "var(--erv-primary) !important",
            "&:hover": {
              backgroundColor: lightMode ? "#fff" : "rgba(0,0,0,0.2)",
              color: lightMode ? "#fff" : "rgba(255,255,255,0.6)",
            },
          },
          ".ps-submenu-content": {
            backgroundColor: lightMode ? "#fff" : "rgba(0,0,0,0.1)",
            color: lightMode ? "initial" : "rgba(255,255,255,0.7)",
            "&:hover": {
              backgroundColor: lightMode ? "#fff" : "rgba(0,0,0,0.1)",
              color: lightMode ? "initial" : "rgba(255,255,255,0.6)",
            },
            ".ps-menu-button": {
              height: "44px",
              paddingLeft: "65px",
            },
            ".sub-sub-menu .ps-menu-button": {
              height: "44px",
              paddingLeft: "75px",
            },
          },
          "&.ps-collapsed": {
            ".ps-submenu-expand-icon": {
              visibility: "hidden",
              opacity: "0",
              transition: "opacity 0.1s",
            },
            ".ps-submenu-content .ps-menu-button": {
              paddingLeft: "35px",
            },
            ".sub-sub-menu .ps-menu-button": {
              paddingLeft: "45px",
            },
            ".ps-submenu-root.ps-open .ps-menu-icon": {
              color: "#fff",
              backgroundColor: "var(--erv-primary) !important",
            },
          },
        }}
      >
        <Menu>
          {sortedMenuItems?.map((menu: any, index: number) =>
            !menu?.subMenu?.length ? (
              <MenuItem
                key={index}
                icon={menu?.icon}
                active={pathname.includes(menu?.to)}
                component={<Link to={menu?.to} />}
              >
                {menu?.menu}
              </MenuItem>
            ) : (
              <SubMenu
                key={index}
                label={menu?.menu}
                icon={menu?.icon}
                defaultOpen={pathname.includes(menu?.to)}
              >
                {menu?.subMenu?.map((subMenu: any, index: number) =>
                  !subMenu?.subSubMenu?.length ? (
                    <MenuItem
                      key={index}
                      active={pathname.includes(subMenu?.to.split("/")[2])}
                      component={<Link to={subMenu?.to} />}
                    >
                      {subMenu?.item}
                    </MenuItem>
                  ) : (
                    <SubMenu
                      key={index}
                      label={subMenu?.item}
                      icon={subMenu?.icon}
                      defaultOpen={pathname.includes(subMenu?.to.split("/")[2])}
                    >
                      {subMenu?.subSubMenu?.map(
                        (subSubMenu: any, idx: number) => (
                          <MenuItem
                            key={idx}
                            className="sub-sub-menu"
                            prefix={<BsFillCircleFill fontSize={4} />}
                            active={pathname.includes(subSubMenu?.to)}
                            component={<Link to={subSubMenu?.to} />}
                          >
                            {subSubMenu?.item}
                          </MenuItem>
                        )
                      )}
                    </SubMenu>
                  )
                )}
              </SubMenu>
            )
          )}
        </Menu>
      </Sidebar>
      <div className="profile-details shadow-lg ps-1 pe-4">
        <AvatarDropdown />
      </div>
    </div>
  );
};

export default LeftSidebar;
