import React from "react";
import { PATH_DASHBOARD as PD } from "../path";
import { isAllowed } from "../../services";

const Dashboard = React.lazy(() => import("./Dashboard"));

const dashboardRoute: any = [];
if (isAllowed("DSH", "published")) {
  dashboardRoute.push({
    path: PD.root,
    element: <Dashboard />,
  });
}
export default dashboardRoute;
