import React from "react";
import { PATH_DASHBOARD } from "../../path";
import { isAllowed } from "../../../services";

const SectionDesign = React.lazy(() => import("./SectionDesign"));

const sectionDesignRoute: any = [];
if (isAllowed("SS", "published")) {
  sectionDesignRoute.push(
    ...[
      {
        path: PATH_DASHBOARD.section.sectionDestign,
        element: <SectionDesign />,
      },
    ]
  );
}

export default sectionDesignRoute;
