import { toast } from "react-toastify";
import swal from "sweetalert";

//Env Variable
const API_URL = process.env.REACT_APP_API_URL;
const RES_API_URL = process.env.REACT_APP_RES_API_URL;
const limit: any = process.env.REACT_APP_PAGINATION_LIMIT;

//Method
const getMethod = "GET";
const postMethod = "POST";
const putMethod = "PUT";
const deleteMethod = "DELETE";

//Api Route Names

export const getJewelleriesRoute = (
  skip: number,
  isPOS?: boolean,
  inventoryType?: string,
  ownerId?: string
) => {
  let ownerString = ownerId ? `&ownerId=${ownerId}` : "";
  return `${isPOS ? "pos/" : ""}jewellery/${
    skip * limit
  }/${limit}?sort=desc&inventoryType=${inventoryType}${ownerString}`;
};

export const getPOSJewelleriesRoute = (skip: number) => {
  return `pos/jewellery/${skip * limit}/${limit}?sort=desc`;
};

export const JewelleryFilterRoute = (skip: number) => {
  return `jewellery/filter/${skip * limit}/${limit}`;
};

export const getSalesRoute = (skip: number) => {
  return `${posSalesRoute}/${skip * limit}/${limit}?sort=desc`;
};

export const customRoute = (route: string, skip: number) => {
  return `${route}/${skip * limit}/${limit}?sort=desc`;
};

export const getRouteWithPaginate = (route: string, skip: number) => {
  return `${route}/${skip * limit}/${limit}?sort=desc`;
};

export const jewelleryTypeFilterRoute = (id?: string, status?: string) => {
  return `jewellery-type/filter${status ? "?status=" + status : ""}${
    id ? "&ownerId=" + id : ""
  }`;
};

export const jewelleryRoute = (id?: string, type?: string) => {
  return `jewellery${id ? "/" + id : ""}${type ? "/" + type : ""}`;
};

export const gemsRoute = (id?: string, type?: string) => {
  return `gemstones${id ? "/" + id : ""}${type ? "/" + type : ""}`;
};

// export const gemstonesRoute = (id: string) => {
//   return `${jewelleryRoute}/${id}/gemstones`
// }

// export const diamondsRoute = (id: string) => {
//   return `${jewelleryRoute}/${id}/diamonds`
// }

export const getPersons = (skip: number, keyword?: string) => {
  return `persons/${skip * limit}/${limit}?keyword=${keyword || ""}`;
};

export const getJewellerySuggest = () => {
  return `jewellery/suggestion/0/30`;
};

const personsRoute = "persons";
const salesRoute = "sales";
const gemstonesRoute = "gemstones";
const jewelleryForRoute = "jewellery-for";
const jewelleryTypeRoute = "jewellery-type";
const jewelleryWeightUnitRoute = "jewellery-weight-unit";
const gemsStoneTypeRoute = "gems-stone-type";
const gemsAvgSizesRoute = "gems-size";
const diamondAvgSizesRoute = "diamonds-size";
const gemsWeightUnitRoute = "gems-weight-unit";
const stoneTypesRoute = "gems-stone-type";
const stockTypesRoute = "gems-stock-type";
const buyingStateRoute = "gems-buying-state";
const gemsOriginsRoute = "gems-origin";
const gemsClarityRoute = "gems-clarity";
const gemsColorRoute = "gems-color";
const gemsShapeAndCutRoute = "gems-shape-and-cut";
const gemsBuyingStateRoute = "gems-buying-state";
const gemsTreatmentRoute = "gems-treatment";
const materialRoute = "jewellery-material";
const materialPurityRoute = "jewellery-material-purity";
const materialColorRoute = "jewellery-material-color";
const currencyRoute = "jewellery-currency";
const paymentMethodsRoute = "payment/method";
const paymentRoute = "payment/jewellery";
const posSalesRoute = "sales/jewellery";

export {
  getMethod,
  postMethod,
  putMethod,
  deleteMethod,
  personsRoute,
  salesRoute,
  gemstonesRoute,
  jewelleryForRoute,
  jewelleryTypeRoute,
  jewelleryWeightUnitRoute,
  gemsStoneTypeRoute,
  gemsAvgSizesRoute,
  diamondAvgSizesRoute,
  gemsWeightUnitRoute,
  stoneTypesRoute,
  stockTypesRoute,
  buyingStateRoute,
  gemsOriginsRoute,
  gemsClarityRoute,
  gemsColorRoute,
  gemsShapeAndCutRoute,
  gemsBuyingStateRoute,
  gemsTreatmentRoute,
  materialRoute,
  materialPurityRoute,
  materialColorRoute,
  currencyRoute,
  paymentMethodsRoute,
  paymentRoute,
  posSalesRoute,
};

export const actionData = async (method: string, route: string, data?: any) => {
  try {
    const response = await fetch(`${API_URL}/${route}`, {
      method: method,
      headers: { "Content-type": "application/json" },
      mode: "cors",
      cache: "default",
      body: data && JSON.stringify(data),
    });
    const json = await response.json();
    return json;
  } catch (error) {
    return toast.error("Something Wrong!");
  }
};

export const getDataService = async (
  route: string,
  setLoading: any,
  setData: any,
  setPage?: any,
  skip?: number
) => {
  setLoading(true);
  actionData(getMethod, route).then((result) => {
    setData(result.data);
    setPage &&
      setPage({
        current: skip,
        total: result.count,
      });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });
};

export const dataService = async (
  method: any,
  formData: any,
  required: any,
  route: string,
  sendNull?: boolean
) => {
  let data: any = {};

  for (let [key, value] of Object.entries(formData)) {
    if (sendNull) {
      if (typeof value === "string") {
        data[key] = value.trim();
      } else {
        data[key] = value;
      }
    } else {
      if (value !== null && value !== "") {
        if (typeof value === "string") {
          data[key] = value.trim();
        } else {
          data[key] = value;
        }
      }
    }
  }

  for (const field of required) {
    if (data.hasOwnProperty(field) === false) {
      return toast.error("Please fill data!");
    }
  }
  return actionData(method, route, data);
};
export const responseService = (
  result: any,
  handleGetData: any,
  handleClose?: any,
  skip?: number | null,
  sorting?: number | null,
  setLoading?: any
) => {
  if (result?.statusCode === 200) {
    toast.success(result?.message || "Successfully done!");
    handleGetData(skip, sorting);
    handleClose && handleClose();
  } else {
    toast.error(result?.message);
    setLoading && setLoading(false);
  }
};

export const responseForRoutes = (result: any, history: any, route: string) => {
  if (result.statusCode === 200) {
    swal(result.message, {
      icon: "success",
      buttons: [false],
      timer: 1500,
    });
    history(`/${route}`);
  } else {
    toast.error(result?.message);
  }
};
export const deleteService = async (
  name: string,
  data: any,
  route: string,
  responseAction: any
) => {
  swal({
    // title: `Are you sure to delete?`,
    text: `Are you sure to delete ${name}!`,
    icon: "warning",
    buttons: [true, true],
    dangerMode: true,
  }).then(async (willDelete) => {
    if (willDelete) {
      responseAction(await actionData(deleteMethod, route, data));
    }
  });
};

export const setUpload = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/upload/products`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: data,
  });

  return response.json();
};

export const deleteUpload = async (data: any) => {
  const response = await fetch(`${RES_API_URL}/media/images`, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  return response.json();
};

export const uploadAvatar = async (data: any, route: any) => {
  const response = await fetch(`${RES_API_URL}/upload/${route}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: data,
  });

  return response.json();
};

export const deleteAvatar = async (data: any, route?: any) => {
  const response = await fetch(
    `${RES_API_URL}/media/${route}?file=${data.file}`,
    {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    }
  );

  return response.json();
};
export const deleteMutliAvatar = async (data: any, route?: any) => {
  const response = await fetch(`${RES_API_URL}/medias/${route}`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  return response.json();
};
