import AppLogo from "./AppLogo";

const AppLoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center position-fixed end-0 bottom-0 w-100 h-100 min-vh-100 min-vw-100">
      <AppLogo style={{ opacity: "0.3" }} width={100} alt="" />
    </div>
  );
};

export default AppLoading;
