import React from "react";
import { toast } from "react-toastify";

interface InternetStatusProps {}

const InternetStatus: React.FC<InternetStatusProps> = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);

  React.useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (navigator.onLine) {
        toast.success(`You're internet connection was stored!`);
      } else {
        toast.error(`You are currently offline!`);
      }
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return <></>;
};

export default InternetStatus;
