import { useCallback, useContext, useEffect } from "react";
import { NavDropdown, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { VariableContext } from "../../context/Variable";
import { logout } from "../../services";
import { getRequest } from "../../services/fetch";
import { AuthenticateContext } from "../../context/Auth";
import { BsBoxArrowRight, BsPerson, BsShieldLock } from "react-icons/bs";
import { PATH_ACCOUNT } from "../../routes/path";
import { Lang } from "..";
const RES_API_URL = process.env.REACT_APP_RES_API_URL;

function AvatarDropdown() {
  // const [showmenu, setShowmenu] = useState(false);
  const [, setAuthenticate] = useContext(AuthenticateContext);
  const history = useNavigate();
  const [variable, setVariable] = useContext(VariableContext);
  const [Authenticate] = useContext(AuthenticateContext);
  const user = Authenticate?.userData;

  const handleGetData = useCallback(() => {
    getRequest({ route: `auth/user/${user?.id}` }).then((result: any) => {
      setVariable({
        ...variable,
        fullName: result?.data?.firstName,
        avatar: result?.data?.avatar,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const handleLogout = () => {
    setTimeout(() => {
      setAuthenticate({ isAutharize: false, userData: {} });
      logout();
      history("/");
    }, 300);
  };

  return (
    <div className="avatar-dropdown">
      {/* <NavDropdown
        title={
          <div className="d-flex">
            <span className="header-avatar">
              <Image
                src={
                  variable?.avatar?.fileName
                    ? `${RES_API_URL}/assets/avatar/${variable?.avatar?.fileName}`
                    : "/images/avatar.png"
                }
              />
            </span>
            <div className="ms-3">
              <p className="fw-bold mb-1 header-avatar-name">
                {variable?.fullName}
              </p>
              <small className="text-muted">
                {user ? user?.userRole?.userRole : ""}
              </small>
            </div>
          </div>
        }
        show={showmenu}
        onMouseEnter={() => setShowmenu(true)}
        onMouseLeave={() => setShowmenu(false)}
      >
        <div className="p-2 d-flex align-items-center flex-column object-fit">
          <Image
            src={
              variable?.avatar?.fileName
                ? `${RES_API_URL}/assets/avatar/${variable?.avatar?.fileName}`
                : "/images/avatar.png"
            }
          />
          <p className="fw-bold mt-2 mb-1 header-avatar-name">
            {user?.fullName}
          </p>
          <small className="text-muted">
            {user ? user?.userRole?.userRole : ""}
          </small>
        </div>

        <Link to={PATH_ACCOUNT.profile} className="nav-link dropdown-item">
          <BsPerson size={18} />{" "}
          <span className="text-muted ms-2">Profile</span>
        </Link>

        <Link
          to={PATH_ACCOUNT.changePassword}
          className="nav-link dropdown-item"
        >
          <BsLock /> <span className="text-muted ms-2">Change password</span>
        </Link>

        <NavDropdown.Item className="nav-link" onClick={() => handleLogout()}>
          <BsBoxArrowRight /> <span className="text-muted ms-2">Sign out</span>
        </NavDropdown.Item>
      </NavDropdown> */}
      <div className="avatar-dropdown">
        <NavDropdown
          title={
            <div className="d-flex align-items-center">
              <div className="profile-content">
                <Image
                  src={
                    variable?.avatar?.fileName
                      ? `${RES_API_URL}/assets/avatar/${variable?.avatar?.fileName}`
                      : "/images/avatar.png"
                  }
                />
              </div>
              <div className="name-job">
                <div className="profile_name">{user?.firstName}</div>
                <div className="job">{user?.userRole?.userRole}</div>
              </div>
            </div>
          }
          // show={showmenu}
          // onClick={() => setShowmenu(!showmenu)}
          // onMouseEnter={() => setShowmenu(true)}
          // onMouseLeave={() => setShowmenu(false)}
        >
          <div className="p-2 d-flex align-items-center flex-column object-fit">
            <img
              alt=""
              src={
                user.data && user.data.avatar
                  ? RES_API_URL + user.data.avatar.url
                  : "/images/avatar.png"
              }
            />
            <p className="fw-bold mt-2 mb-1 header-avatar-name">
              {user?.firstName}
            </p>
            <small className="text-muted">{user.data?.userRole}</small>
          </div>

          <Link to={PATH_ACCOUNT.profile}>
            <NavDropdown.Item as={"button"} className="nav-link">
              <BsPerson fontSize={18} />
              <span className="text-muted ms-2">
                <Lang label="profile" />
              </span>
            </NavDropdown.Item>
          </Link>

          <Link to={PATH_ACCOUNT.changePassword}>
            <NavDropdown.Item as={"button"} className="nav-link">
              <BsShieldLock fontSize={18} />
              <span className="text-muted ms-2">
                <Lang label="changePassword" />
              </span>
            </NavDropdown.Item>
          </Link>

          <NavDropdown.Item className="nav-link" onClick={() => handleLogout()}>
            <BsBoxArrowRight fontSize={18} />
            <span className="text-muted ms-2">
              <Lang label="logout" />
            </span>
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </div>
  );
}

export default AvatarDropdown;
