import React from "react";
import { PATH_DASHBOARD } from "../path";
import { isAllowed } from "../../services";

const RoleModule = React.lazy(() => import("./RoleModule"));

const roleMouduleRoutes: any = [];
if (isAllowed("ROLE-PERMISSION", "published")) {
  roleMouduleRoutes.push(
    ...[
      {
        path: PATH_DASHBOARD.configuration.rolePermisson,
        element: <RoleModule />,
      },
      //   {
      //     path: PATH_DASHBOARD.links.addMenu,
      //     element: <AddMenu />,
      //   },
      //   {
      //     path: PATH_DASHBOARD.links.menuDetail + "/:menuId",
      //     element: <MenuDetail />,
      //   },
    ]
  );
}
export default roleMouduleRoutes;
