import { isAllowed } from "../../services";
import sectionsRoute from "./section";
import sectionDesignRoute from "./setionDesign";

const sectionRoute: any = [];
if (isAllowed("STN", "published")) {
  sectionRoute.push(...sectionsRoute);
  sectionRoute.push(...sectionDesignRoute);
}
export default sectionRoute;
