import React from "react";
import { isAllowed } from "../../services";
import { PATH_DASHBOARD } from "../path";

// staffs member of organization
const Setting = React.lazy(() => import("./Setting"));
const settingRoutes: any = [];

if (isAllowed("STG", "published")) {
  settingRoutes.push({
    path: PATH_DASHBOARD.setting.root,
    element: <Setting />,
  });
}

export default settingRoutes;
