import React from "react";
import { PATH_ACCOUNT as PA } from "../../routes/path";

// account
const Profile = React.lazy(() => import("./Profile"));
const ChangePassword = React.lazy(() => import("./ChangePassword"));

const accountRoutes = [
  {
    path: PA.profile,
    element: <Profile />,
  },
  {
    path: PA.changePassword,
    element: <ChangePassword />,
  },
];

export default accountRoutes;
