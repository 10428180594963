import React from "react";
import { PATH_DASHBOARD } from "../../../path";

const Blog = React.lazy(() => import("./Blogs"));

const blogRoute: any = [];
blogRoute.push(
  ...[
    {
      path: PATH_DASHBOARD.content.blog,
      element: <Blog />,
    },
  ]
);

export default blogRoute;
