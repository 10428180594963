import { PATH_STAFFS, PATH_DASHBOARD as PD } from "../../../routes/path";

import {
  BsGear,
  BsSpeedometer2,
  BsLink,
  BsSignIntersection,
  BsFileImage,
  BsFile,
  BsJournal,
  BsPeople,
} from "react-icons/bs";
import { getStoreModules } from "../../../services";

const modules = getStoreModules();
const menuItems: any = [];
const isPublished = (mCode: any) => {
  const module =
    modules &&
    modules.find((module: any) => module.code === mCode && module.published);

  return module;
};
const sortOrder = (mCode: any) => {
  const module =
    modules?.find((module: any) => module.code === mCode)?.sortOrder || 0;

  return module;
};

const moudleName = (mCode: any) => {
  const module = modules?.find((module: any) => module.code === mCode);

  if (module) {
    return module.name;
  }
};
// Dashboard

//Links
if (isPublished("LNK")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("LNK"),
    icon: <BsLink fontSize={20} />,
    to: PD.links.root,
    subMenu: [],
    sortOrder: sortOrder("LNK"),
  });

  if (isPublished("SM")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("SM"),
      icon: "",
      to: PD.links.link,
      sortOrder: sortOrder("SM"),
    });
  }
  if (isPublished("MENU")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("MENU"),
      icon: "",
      to: PD.links.menu,
      sortOrder: sortOrder("MENU"),
    });
  }
}
if (isPublished("STN")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("STN"),
    icon: <BsSignIntersection fontSize={20} />,
    to: PD.section.root,
    subMenu: [],
    sortOrder: sortOrder("STN"),
  });

  if (isPublished("STNS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("STNS"),
      icon: "",
      to: PD.section.sections,
      sortOrder: sortOrder("STNS"),
    });
  }
  if (isPublished("SS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("SS"),
      icon: "",
      to: PD.section.sectionDestign,
      sortOrder: sortOrder("SS"),
    });
  }
}
if (isPublished("CNT")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("CNT"),
    icon: <BsFile fontSize={20} />,
    to: PD.content.root,
    subMenu: [],
    sortOrder: sortOrder("CNT"),
  });

  if (isPublished("PGS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("PGS"),
      icon: "",
      to: PD.content.page,
      sortOrder: sortOrder("PGS"),
    });
  }
  if (isPublished("BLG")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("BLG"),
      icon: "",
      to: PD.content.blog,
      sortOrder: sortOrder("BLG"),
    });
  }
  if (isPublished("NWS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("NWS"),
      icon: "",
      to: PD.root,
      sortOrder: sortOrder("NWS"),
    });
  }
}
if (isPublished("MM")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("MM"),
    icon: <BsFileImage fontSize={20} />,
    to: PD.mediaManager.root,
    subMenu: [],
    sortOrder: sortOrder("MM"),
  });

  if (isPublished("IMG")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("IMG"),
      icon: "",
      to: PD.mediaManager.image,
      sortOrder: sortOrder("IMG"),
    });
  }
  if (isPublished("DCS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("DCS"),
      icon: "",
      to: PD.mediaManager.document,
      sortOrder: sortOrder("DCS"),
    });
  }
  if (isPublished("VDS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("VDS"),
      icon: "",
      to: PD.mediaManager.video,
      sortOrder: sortOrder("VDS"),
    });
  }
}
if (isPublished("FMS")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("FMS"),
    icon: <BsJournal fontSize={20} />,
    to: PD.forms.root,
    subMenu: [],
    sortOrder: sortOrder("FMS"),
  });

  if (isPublished("CTS")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("CTS"),
      icon: "",
      to: PD.forms.contact,
      sortOrder: sortOrder("CTS"),
    });
  }
  if (isPublished("ATT")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("ATT"),
      icon: "",
      to: PD.forms.appoinment,
      sortOrder: sortOrder("ATT"),
    });
  }
  if (isPublished("EQY")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("EQY"),
      icon: "",
      to: PD.forms.enquiry,
      sortOrder: sortOrder("EQY"),
    });
  }
}

if (isPublished("CONF")) {
  const index = menuItems?.length;
  menuItems.push({
    menu: moudleName("CONF"),
    icon: <BsGear fontSize={20} />,
    to: PD.configuration.root,
    subMenu: [],
    sortOrder: sortOrder("CONF"),
  });
  if (isPublished("MOD")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("MOD"),
      icon: "",
      sortOrder: sortOrder("MOD"),
      to: PD.configuration.modules,
    });
  }

  if (isPublished("ROLE-PERMISSION")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("ROLE-PERMISSION"),
      icon: "",
      to: PD.configuration.rolePermisson,
      sortOrder: sortOrder("ROLE-PERMISSION"),
    });
  }
  if (isPublished("ROLE")) {
    menuItems[index]?.subMenu.push({
      item: moudleName("ROLE"),
      icon: "",
      sortOrder: sortOrder("ROLE"),
      to: PD.configuration.role,
    });
  }
}
if (isPublished("USR")) {
  menuItems.push({
    menu: moudleName("USR"),
    icon: <BsPeople fontSize={20} />,
    to: PATH_STAFFS.root,
    sortOrder: sortOrder("USR"),
  });
}
if (isPublished("STG")) {
  menuItems.push({
    menu: moudleName("STG"),
    icon: <BsGear fontSize={20} />,
    to: PD.setting.root,
    subMenu: [],
    sortOrder: sortOrder("STG"),
  });
}
if (isPublished("DSH")) {
  menuItems.push({
    menu: moudleName("DSH"),
    icon: <BsSpeedometer2 fontSize={20} />,
    to: PD.root,
    sortOrder: sortOrder("DSH"),
  });
}
menuItems.sort((a: any, b: any) => a.sortOrder - b.sortOrder);

export default menuItems;
